
* { 

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
  font-family: IRANSans !important;
  height: auto;
  width: auto;
  text-decoration: none;

}

input {

  outline: unset;

}

p , a , span {

  direction: rtl;
  width: unset;
  height: unset;

}

.MuiSvgIcon-root {

  cursor: pointer;
  margin: unset;

}

.App {

  height: 100vh;


}

.SidebarMobileActions {

  fill: white;

}

body,html {

  height: 100vh;
  overflow: hidden;

}


.NavbarWrapper { 

  position: sticky;
  top: 0;
  width: 100%;
  height: 55px;
  background-color: white;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  z-index: 999;

}

@media only screen and (max-width: 1340px) {

  .NavbarWrapper {

    justify-content: space-between;

  }

}

.LogoWrapper {

    position: relative;
    width: 10%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    user-select: none;
    cursor: pointer;

}

@media only screen and (max-width: 1340px) {

  .LogoWrapper {

     width: 150px;
     margin-left: 3%;

  }

}

.LogoText {

    position: relative;
    font-size: 1vw !important;
    font-weight: bold;
    color: #214185 !important;
    font-family: Hiragino !important;

}

@media only screen and (max-width: 1340px) {

  .LogoText {

     font-size: 1.1rem !important;

  }

}

.LogoText2 {

  color: #4890cd !important;
  font-family: Hiragino !important;
  
}

.NavbarLogo {

  position: relative;
  width: 3vw;
  max-width: 2.8rem;
  padding: .1vw;

}

@media only screen and (max-width: 1340px) {

  .NavbarLogo {

    width: 2.8rem;

  }

}

.MenuToggle {

    position: relative;
    width: 5%;
    height: 60px;
    cursor: pointer;
    display: block;

}

@media only screen and (max-width: 1340px) {

  .MenuToggle {

      display: none;

  }

}

.MenuToggle > .MenuToggleIcon {

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 2vw !important;
  height: 2vw !important;

}

.MenuToggleMobile {

  width: 5%;
  height: 60px;
  cursor: pointer;
  display: none;

}

@media only screen and (max-width: 1340px) {

  .MenuToggleMobile {

    width: 15%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;

  }

}

.MenuToggleMobile > .hamburger-react {

width: 2rem !important;
height: 2rem !important;

}


.NavbarItems {

  position: relative;
  width: 36%;
  height: 60px;
  margin-right: 1%;

}

@media only screen and (max-width: 1340px) {

  .NavbarItems {

      display: none;

  }

}

.NavbarItems > ul{

  position: relative;
  width: 90%;
  height: 100%;
  list-style: none;
  color: white !important;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  float: right;

}

.NavbarItems > ul > li{

  font-weight: 500;
  cursor: pointer;
  text-align: center;

}

.NavbarActions {

    position: relative;
    width: 25%;
    height: 100%;
    margin-right: 14%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;

}

@media only screen and (max-width: 1340px) {

  .NavbarActions {

      display: none;

  }

}

.NavbarActionsDropDown {

  position: absolute;
    width: 100%;
    height: 150px;
    margin-top: 200px;
    z-index: 99999;

}

.NavbarActionsDropDown > ul {

  position: relative;
  width: 100%;
  height: 100%;
  list-style: none;

}

.NavbarActionsDropDown > ul > a > li {

  position: relative;
  width: 90%;
  height: 30%;
  list-style: none;
  display: block;
  margin: auto;
  margin-top: 2%;
  cursor: pointer;

}

.NavbarActionsDropDown > ul > a > li:hover {
  
  background-color: #d6e7f741;

}

.NavbarActionsDropDown > ul > a > li > span {

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50% , -50%);
  width: 100%;
  text-align: center;
  cursor: pointer;
  user-select: none;

}

.NotificationDropDown {

  position: absolute;
    width: 100%;
    height: 150px;
    margin-top: 60%;
    z-index: 99999;
    left: 77%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 15px;

}

.NotificationDropDown > ul {

  position: relative;
  width: 100%;
  height: 100%;
  list-style: none;


}

.NotificationDropDownEmpty {

  direction: rtl;
  text-align: center;
  width: 100%;
  text-align: center;

}

.NotificationDropDown > ul > li {

  position: relative;
  width: 90%;
  height: 30%;
  list-style: none;
  display: block;
  margin: auto;
  margin-top: 2%;
  cursor: pointer;

}

.NotificationDropDown > ul > li:hover {
  
  background-color: #d6e7f741;

}

.NotificationDropDown > ul > li > span {

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50% , -50%);
  width: 100%;
  text-align: center;
  
  cursor: pointer;
  user-select: none;

}

.LangToggle {

    position: relative;
    width: 5%;
    height: 60px;
    cursor: pointer;

}

.LangToggleM {

  position: relative;
  width: 2.4rem;
  height: 40px;
  cursor: pointer;

}

.LangToggleM > img {

  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50% , -50%);

}

@media only screen and (max-width: 1340px) {

  .LangToggle {

      display: none;

  }

}

.LangToggle > img {

  position: absolute;
  width: 40%;
  left: 50%;
  top: 50%;
  transform: translate(-50% , -50%);

}

@media only screen and (max-width: 1340px) {

  .LangToggle > img {

      width: 100%;

  }

}

.UserControl {
  
  position: relative;
  width: 15vw;
  height: 70%;
  background-color: #f2f3f7;
  border-radius: 30px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  max-width: 230px;

}

.Userlogo {

  width: 15px;
  height: 15px;

}

.UserEmail {

  color: #204285;
  user-select: none !important;

}

.UserToggle {

  width: 10px;
  height: 9px;

}

.NavbarActions > .UserActionIcons {

  position: relative;
  width: 1.4rem !important;
  height: 1.4rem !important;
  cursor: pointer;
  margin-left: 20px;

}

.SidebarWrapper {

    position: relative;
    float: right;
    background-color: #fbfcfe;
    height: 90vh;
    width: 10vw;
    margin-top: 20px;
    border-radius: 20px 0 0 0;
    transition: 300ms;
    overflow: auto;

}

.r0 {

  right: 0;

}

.r18 {

  right: -18%;
  margin-right: -18%;

}

@media only screen and (max-width: 1340px) {

  .SidebarWrapper {

      display: none;

  }

}

.SidebarWrapper > ul {
  
    position: relative;
    width: 100%;
    height: 85%;
    list-style: none;
    margin-top: 40px;
    overflow-y: scroll;

}

.SidebarWrapper > ul > a > li {
  
  position: relative;
  width: 100%;
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  border-radius: 10px;
  direction: ltr !important;

}

.SidebarWrapper > ul > a {
  
  position: relative;
  width: 100%;
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  border-radius: 10px;
  margin-top: 10px;
  direction: ltr !important;

}

.SidebarWrapper > ul > a > li:hover {
  
  background-color: #d6e7f741;

}

.SidebarWrapper > ul > a > li > .MuiSvgIcon-root {
  
  position: relative;
  margin-top: 3px;
  width: 1.8rem !important;
  height: 1.8rem !important;

}

.SidebarWrapper > ul > a > li > span {

  color: #385894;
  margin-top: 9px;
  margin-right: 10px;
  direction: ltr;
  text-align: right;
  


}

.MainContentWrapper {

    position: relative;
    height: 100%;
    padding: 0px 20px 15px 10px;
    transition: 300ms;
    overflow-y: auto;
    padding-bottom: 300px;
    margin-top: 20px;
    margin-left: 10px;

}

@media only screen and (max-width: 1340px) {

  .MainContentWrapper {

      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: safe;
      flex-direction: column;

  }

}

.DashboardWrapper {

  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

}

.DashboardUlWrapper {
  width: auto;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  direction: rtl;
}

@media only screen and (max-width: 1340px) {

  
.DashboardUlWrapper {
  position: absolute;
}

}

.DashboardUl {
  width: 100%;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  direction: rtl;
  overflow: hidden;
}

@media only screen and (max-width: 1340px) {

  .DashboardUl {

    height: 810px;
    overflow: auto;

  }

}

@media only screen and (max-width: 1340px) {

  .DashboardWrapper {

    padding: unset;

  }

}

.w18 {

  width: 18vw;

}

.w0 {

  width: 0%;

}

.w79 {

  width: 78%;

}

.w98 {

  width: 100%;

}

.MainContentWrapper > .DashboardWrapper > .DashboardUl > .DashboardUlWrapper > ul {

  width: auto;
  height: auto;
  list-style: none;
  display: flex;
  flex-flow:wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  direction: rtl;

}

@media only screen and (max-width: 1340px) {

  .MainContentWrapper > .DashboardWrapper > .DashboardUl > .DashboardUlWrapper > ul {

      width: 100% !important;
      flex-flow: nowrap !important;

  }

}

.MainContentWrapper > .DashboardWrapper > .DashboardUl > .DashboardUlWrapper > ul > li {

  width: 180px;
  height: 225px;
  list-style: none;
  border-radius: 25px;
  margin: 0 0px 20px 20px;

}

.MainContentWrapper > .DashboardWrapper > .DashboardUl > .DashboardUlWrapper > ul > li > img {

  width: 52px;
  height: 52px;
  margin-top: 16px;

}

.MainContentWrapper > .DashboardWrapper > .DashboardUl > .DashboardUlWrapper > ul > li > h3 {

  margin: unset;
  margin-top: 9px;
  margin-bottom: 14px;

}

.NotificationWrapper {

  position: relative;
  width: 90%;
  height: auto;
  background-color: #e1f4ff;
  border: 1px rgb(197, 197, 197) solid;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: wrap-reverse;
  flex-direction: row;
  padding: 20px 10px 15px 10px;
  margin-bottom: 20px;
  float: right;

}

@media only screen and (max-width: 1340px) {

  .NotificationWrapper {

      width: 90%;
      height: auto;
      margin-left: 5%;

  }

}

.NotificationWrapper > span {

  direction: rtl;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-flow: wrap row;
  justify-content: space-between;

}

.NotificationWrapper > ul {

  position: red;
  background-color: red;
  width: 100%;
  height: 100%;
  
}

.NotificationClose {

  width: 95px;
  height: 35px;
  background-color: #1ba27a;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

}

@media only screen and (max-width: 1340px) {

  .NotificationClose {

      margin-top: 13px;
      margin-left: -5px;
      margin-right: 10px;
      width: auto;

  }

}

.NotificationClose > span {

  color: white !important;
  width: 100px;
  text-align: center;

}

.NotificationWrapperWi {

  position: relative;
  width: 100%;
  height: 280px;
  background-color: #e1f4ff;
  border: 1px rgb(197, 197, 197) solid;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  overflow: hidden;

}

.NotificationWrapperWiInner {

  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;

}

.NotificationWrapperWiInner > span {

  text-align: right;

}

.NotificationWrapperWiItems {

  position: relative;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: space-around;
  list-style-type: square;
  direction: rtl;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 30px;
  opacity: .8;


}

.NotificationWrapperWiItems > ul > li {

  margin-top: 12px;

}

@media only screen and (max-width: 1340px) {

  .NotificationWrapperWi {

      width: 90%;
      height: auto;
      margin-left: 5%;

  }

}

.NotificationWrapperWi > span {

  direction: rtl;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-flow: wrap row;
  justify-content: space-between;
  text-align: right;
  height: auto;
  direction: rtl;

}


.NotificationWrapperWi > ul {

  position: red;
  background-color: red;
  width: 100%;
  height: 100%;
  
}

.NotificationClose {

  width: 95px;
  height: 35px;
  background-color: #1ba27a;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: -7px;
  align-items: center;

}

@media only screen and (max-width: 1340px) {

  .NotificationClose {

    margin-top: 13px;
    margin-left: -5px;
    margin-right: 10px;
    width: auto;

  }

}



.NotificationClose > span {

  color: white !important;
  width: 100px;
  text-align: center;

}

.MainItemsIcon {

  position: relative;
  display: block;
  margin: auto;
  width: 80px;
  margin-top: 20px;

}

.MainItemsName {

  position: relative;
  display: block;
  margin: auto;
  width: 100%;
  text-align: center;
  font-weight: bold;
  direction: rtl;
  color: #204285;
  margin-bottom: 20px;

}

.Price {

  position: relative;
  width: 153px;
  height: 28px;
  border-radius: 30px;
  margin: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 8px;

}

.PriceOff {

  position: relative;
  background-color: red;
  color: white !important;
  border-radius: 30px;
  padding: 1px 10px;
  float: left;
  

}

.PriceText {

  color: gray;

}

.MinMax {

  color: gray;

}

.UserControlProfile {

    position: relative;

}
 
.SidebarMobileWrapper {

  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.863);
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
  z-index: 9999999999999;
  backdrop-filter: blur(3px);
  display: none;

}

@media only screen and (max-width: 1340px) {

  .SidebarMobileWrapper {

      display: block;
      overflow-y: auto;

  }

}

.SidebarMobileWrapper > ul {

  position: relative;
  display: block;
  margin: auto;
  float: right;
  width: 100%;
  height: 90%;
  padding-top: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  overflow-y: auto;

}

.SidebarMobileWrapper > ul > a > li {

  width: 65%;
  height: 20px;
  border-bottom: 1px rgb(104, 104, 104) solid;
  margin: 12px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  padding-bottom: 24px;

}

.SidebarMobileWrapper > ul > a {

  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.SidebarMobileWrapper > ul > a > li > .MuiSvgIcon-root {
  
  width: 1.8rem !important;
  height: 1.8rem !important;
  fill: #c0c0c0;

}

.SidebarMobileWrapper > ul > a > li > span {

  color: #c0c0c0;
  direction: ltr;
  text-align: center;
  width: 100%;


}

.SidebarMobileWrapperClose {

  position: absolute;
  display: block;
  right: 58px;
  top: 24px;
  cursor: pointer;

}

.SidebarMobileWrapper > .SidebarMobileActions > .MuiSvgIcon-root {

  fill: white;
  width: 2rem !important;
  height: 2rem !important;

}

.dn {

  display: none;

}

.db {

  display: block;

}

.NotificationsNoneIconWrapper {

  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin-left: 20px;

}

.UserActionIconsMobile {

  width: 2.1rem !important;
  height: 2.1rem !important;
  cursor: pointer;

}

.NavbarWrapper > .NavbarActions > .NotificationsNoneIconWrapper > .MuiSvgIcon-root {

  height: 1.6vw;
  width: 1.6vw;

}

.NavbarWrapper > .NavbarActions  > .MuiSvgIcon-root {

  height: 1.4vw !important;
  width: 1.4vw !important;

}

.SidebarMobileActions {

  width: 150px;
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;

}

.AccountInformationWrapper {

  position: relative;
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 24px;

}

.AccountInformationWrapper > ul {

  position: relative;
  width: 100%;
  height: 75%;
  list-style: none;

}

.AccountInformationWrapper > ul > li {

  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 1px rgba(128, 128, 128, 0.349) solid;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-flow: row-reverse;
  padding: 15px;

}

.InformationActions {

  width: 200px;
  display: flex;
  justify-content: safe;
  align-items: center;
  flex-flow: row;
  text-align: left;

}

.InformationActions > span {

  width: 100%;
  cursor: pointer;
  font-weight: 700;

}

.InformationActions > .MuiSvgIcon-root {

  cursor: pointer;

}

.InformationType {

  width: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row-reverse;
  text-align: left;

}

.InformationType > span {

  text-align: right;
  width: 100%;
  padding-right: 10%;
  font-weight: 700;

}

.InformationData {

  width: 10%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row-reverse;

}

.InformationData > span {

  text-align: right;
  width: 100%;
  direction: rtl;
  font-weight: 500;

}

.AccountInformationWrapperHeader {

  position: relative;
  width: 100%;
  height: 70px;
  border-bottom: 1px rgba(131, 131, 131, 0.123) solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}

.AccountInformationHeaderTitle {

  padding: 24px;
  margin-right: 12px;
  font-weight: 700;

}

.AccountInformationChange {

  position: relative;
  width: 120px;
  height: 35px;
  background-color: #1ba27a;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: -7px;
  align-items: center;
  margin-left: 24px;

}

.AccountInformationChange > span > a {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white !important;
  width: 100px;
  text-align: center;

}

.AccountVerificationStatusWrapper {
  
  position: relative;
  background-color: #0a0c97d0;
  padding: 5px 9px;
  color: white !important;
  border-radius: 20px;
  height: 29px;
  border: 1px rgba(128, 128, 128, 0.479) solid;

}

.AccountVerificationStatus {
  
  position: relative;
  padding: 6px 7px;
  left: -11px;
  border-radius: 15px;
  height: 20px;
  color: rgb(2, 173, 2);

}



.BankInformationWrapper {

  position: relative;
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 24px;

}


.BankInformationWrapperHeader {

  position: relative;
  width: 100%;
  height: 70px;
  border-bottom: 1px rgba(131, 131, 131, 0.123) solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-flow: wrap row;
  justify-content: space-between;

}

.BankInformationHeaderTitle {

  padding: 24px;
  margin-right: 12px;
  font-weight: 700;

}

.‌BankInformationChange {

  position: relative;
  width: 250px;
  height: 35px;
  background-color: #1ba27a;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-top: -7px;
  align-items: center;
  margin-left: 24px;

}

.‌BankInformationChange > span > a {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white !important;
  width: 100%;
  text-align: center;

}

.TableWrapper {

  overflow-x: auto;
  overflow-y: auto;
  height: 40vh;
  direction: rtl;

}

table {
  border-collapse: collapse;
  width: 100%;
  direction: rtl;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 0 0 10px 10px;
  
}

@media only screen and (max-width: 1340px) {

  table {

    width: 1000px;

  }

}

td, th {

  padding: 8px;
  text-align: center;
  font-weight: 700;

}

th {

  background-color: #dddddd;
  width: 100%;

}

td {

  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  width: 100%;

}

tr {

  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  width: 100%;

}

td > .btn {

  background-color: red;

}

.ProfileWrapper {
  
  height: auto;
  width: 100%;

}

.LoginHistoryWrapper {

  width: 100%;
  height: auto;

}

.OrdersWrapper {

  width: 100%;
  height: auto;

}

.AccountInformationWrapper {

  position: relative;
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 24px;

}

.AccountInformationWrapper > ul {

  position: relative;
  width: 100%;
  height: 75%;
  list-style: none;

}

.AccountInformationWrapper > ul > li {

  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 1px rgba(128, 128, 128, 0.349) solid;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-flow: row-reverse;
  padding: 15px;

}

.InformationActions {

  width: 200px;
  display: flex;
  justify-content: safe;
  align-items: center;
  flex-flow: row;
  text-align: left;

}

.InformationActions > span {

  width: 100%;
  cursor: pointer;
  font-weight: 700;

}

.InformationActions > .MuiSvgIcon-root {

  cursor: pointer;

  
}

.InformationType {

  width: 20%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row-reverse;
  text-align: left;

}

.InformationType > span {

  text-align: right;
  width: 100%;
  padding-right: 10%;
  font-weight: 700;

}

.InformationData {

  width: 10%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row-reverse;

}

.InformationData > span {

  text-align: right;
  width: 100%;
  direction: rtl;
  font-weight: 500;

}

.AccountEditWrapper {

  position: relative;
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 24px;

}

.AccountEditWrapper > ul {

  width: 100%;
  height: auto;
  list-style: none;
  display: flex;
  justify-content: space-around right;
  flex-flow: row wrap;
  flex-direction: row-reverse;
  align-items: center;
  padding: 20px;

}

.AccountEditWrapper > ul > li {
  
  height: 45px;
  width: 40%;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  direction: ltr;
  text-align: right;
  margin-right: 24px;

}

.AccountEditWrapper > ul > li > input {

  width: 60%;
  height: 80%;
  border-radius: 5px;
  direction: rtl;
  padding: 10px;
  outline: none;

}

.AccountEditWrapper > ul > li > span {

  font-weight: 700;
  width: 100%;

}

.AccountEditWrapperHeader {

  position: relative;
  width: 100%;
  height: 70px;
  border-bottom: 1px rgba(131, 131, 131, 0.123) solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-flow: wrap row;
  justify-content: space-between;

}

.AccountEditHeaderTitle {

  padding: 24px;
  margin-right: 12px;
  font-weight: 700;

}

.AccountEditVerificationStatusWrapper {
  
  position: relative;
  padding: 5px 9px;
  color: rgb(88, 88, 88);
  border-radius: 20px;
  height: 29px;
  border: 1px rgba(128, 128, 128, 0.479) solid;
  margin-left: 20px;

}

.AccountEditVerificationStatus {
  
  position: relative;
  padding: 6px 7px;
  left: -11px;
  border-radius: 15px;
  height: 20px;
  color: rgb(2, 173, 2);

}

.AccountEditFooter {

  position: relative;
  width: 100%;
  height: 70px;
  border-top: 1px rgba(131, 131, 131, 0.123) solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

}

.AccountEditFooterTitle {

  padding: 24px;
  margin-right: 12px;
  font-weight: 500;

}

.AccountEditSubmit {

  width: 130px;
  height: 40px;
  background-color: #1ba27a;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  margin-left: 24px;

}

.AccountEditSubmit > span {
  
  font-weight: 500;
  color: white !important;

}

.BankEditWrapper {

  position: relative;
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 24px;

}

.BankEditWrapper > ul {

  width: 100%;
  height: auto;
  list-style: none;
  display: flex;
  justify-content: space-around right;
  flex-flow: row wrap;
  flex-direction: row-reverse;
  align-items: center;
  padding: 20px;

}

.BankEditWrapper > ul > li {
  
  height: 45px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;
  align-items: center;
  text-align: right;
  margin-right: 24px;

}

.BankEditWrapper > ul > li > div > input {

  width: 70%;
  height: 80%;
  border-radius: 5px;
  direction: rtl;
  padding: 10px;
  outline: none;
  margin-right: 24px;

}

.BankEditWrapper > ul > li > .BankEditLi > span {

  font-weight: 700;
  width: 300px;

}

.BankEditWrapper > UL > li > .BankEditLi {

  width: 300px;
  height: 60px;

}

.BankEditWrapperHeader {

  position: relative;
  width: 100%;
  height: 70px;
  border-bottom: 1px rgba(131, 131, 131, 0.123) solid;

}

.BankEditHeaderTitle {

  padding: 24px;
  margin-right: 12px;
  font-weight: 700;
  float: right;

}

.BankEditVerificationStatusWrapper {
  
  position: relative;
  padding: 5px 9px;
  color: rgb(88, 88, 88);
  border-radius: 20px;
  height: 29px;
  border: 1px rgba(128, 128, 128, 0.479) solid;
  margin-left: 20px;

}

.BankEditVerificationStatus {
  
  position: relative;
  padding: 6px 7px;
  left: -11px;
  border-radius: 15px;
  height: 20px;
  color: rgb(2, 173, 2);

}

.BankEditFooter {

  position: relative;
  width: 100%;
  height: 70px;
  border-top: 1px rgba(131, 131, 131, 0.123) solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
}

.BankEditFooterTitle {

  padding: 24px;
  margin-right: 12px;
  font-weight: 500;

}

.BankEditSubmit {

  width: 130px;
  height: 40px;
  background-color: #1ba27a;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  margin-left: 24px;

}

.BankEditSubmit > span {
  
  font-weight: 500;
  color: white !important;

}

.BankAddAccountWrapper {

  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;

}

@media only screen and (max-width: 1340px) {

  .BankAddAccountWrapper {

    height: auto;

  }

}

.BankAddAccountPanel {

  width: 50%;
  height: 700px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: safe;
  position: relative;
  padding: 10px;
  max-height: 90vh;
  min-height: 65vh;

}

@media only screen and (max-width: 1340px) {

  .BankAddAccountPanel {

    width: 100%;
    height: 100%;
    box-shadow: unset;
    border: unset !important;
    padding: unset;
    height: auto;

  }

}

.BankAddAccountPanel > .MuiSvgIcon-root {

  width: 6rem;
  height: 6rem;
  margin-top: 70px;

}

@media only screen and (max-width: 1340px) {

  .BankAddAccountPanel > .MuiSvgIcon-root {

    margin-top: 15px;

  }

}

.BankAddAccountPanelHeaderText {

  font-weight: 700;
  margin-top: 5px;

}

.BankAddAccountPanelHeaderDescription {

  font-weight: 700;
  margin-top: 10px;

}

.BankAddAccountPanelUlWrapper {
  
  width: 100%;
  height: 320px;

}

.BankAddAccountPanelUlWrapper > ul {

  width: 100%;
  height: 90%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: safe;
  align-items: center;
  margin-top: 10px;

}

.BankAddAccountPanelUlWrapper > ul > li {

  width: 70%;
  height: 80px;
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

}

@media only screen and (max-width: 1340px) {

  .BankAddAccountPanelUlWrapper > ul > li {

    width: 90%;

  }

}

.BankAddAccountPanelUlWrapper > ul > li > .BankAddAccountItemBody {

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row ;

}

.BankAddAccountPanelUlWrapper > ul > li > .BankAddAccountItemBody > div {

  width: 60px;
  position: absolute;
  left: 1px;
  bottom: -1px;
  height: 86%;
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.BankAddAccountPanelUlWrapper > ul > li > .BankAddAccountItemBody > div > span {

  user-select: none;

}


.BankAddAccountPanelUlWrapper > ul > li > .BankAddAccountItemBody > input {

  height: 90%;
  width: 100%;
  outline: none;
  border-radius: 10px;
  margin-top: 10px;
  text-align: right;
  direction: rtl;
  padding: 10px;
  padding-left: 60px;

}

.BankAddAccountPanelUlWrapper > ul > li > .BankAddAccountItemBody > span {

  float: right;
  font-weight: 700;
  text-align: right;
  height: 100%;
  width: 60px;
  border-radius: 10px 0 0 10px;

}

.BankAddAccountItemHeader {
  
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;

}

.BankAccountSubmit {

  width: 70%;
  height: 60px;
  background-color: #3e8ccb;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;

}

.BankAccountSubmit > span {

  color: white !important;

}

.BankAddAccountPanelClose {

  position: absolute;
  top: 24px;
  right: 24px;
  width: 2rem !important;
  height: 2rem !important;

}

.BankAddAccountPanelWrapper {

  position: absolute;

}

.AccountVerifyWrapper {

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;

}

.AccountVerifyPanel {

  width: 40%;
  height: 65vh;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

}

.AccountVerifyLogo {

  width: 5rem;
  margin-top: 40px;

}

.AccountVerifyLogoText {

  position: relative;
  font-size: 30px !important;
  font-weight: 800;
  color: #214185;
  margin-top: 18px;

}

.AccountVerifyLogoText2 {

color: #4890cd;

}

.AccountVerifyIntroText {

  width: 100%;
  text-align: center;
  font-weight: 700;
  direction: rtl;
  margin-top: 20px;
  color: rgb(68, 68, 68);

}

.AccountVerifyDescription1 {

  font-weight: 700;
  margin-top: 140px;
  opacity: .8;

}

.AccountVerifyDescription2 {

  font-weight: 700;
  margin-top: 10px;
  direction: rtl;
  opacity: .8;

}

.StartAccountVerification {

  width: 70%;
  height: 50px;
  background-color: #00A652;
  margin-top: 24px;
  border-radius: 5px;
  box-shadow: 0 4px 10px #00a65346;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

}

.StartAccountVerification > span {
  
  color: white !important;
  font-weight: 700;

}


.StartAccountVerification > span > a {
  
  color: white !important;

}

.AccountVerifyStepWrapper {

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;

}

.AccountVerifyStepPanel {

  width: 60%;
  height: 85vh;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  position: relative;
  direction: rtl;

}

.AccountVerifyStepButtons {
  
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.MuiStepConnector-root {

  display: none !important;

}

.MuiPaper-root {

  background-color: transparent !important;

}

.AccountVerifyStepPanelContent {

  width: 100%;
  height: 60%;

}

.PanelUlWrapper {
  
  width: 100%;
  height: 350px;

}

.AccountVerifyPanelUlWrapper > ul {

  width: 100%;
  height: 90%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: safe;
  align-items: center;
  margin-top: 10px;

}

.AccountVerifyPanelUlWrapper > ul > li {

  width: 70%;
  height: 80px;
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;


}

.AccountVerifyPanelUlWrapper > ul > li > .AccountVerifyItemBody {

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row ;

}

.AccountVerifyPanelUlWrapper > ul > li > .AccountVerifyItemBody > div {

  width: 60px;
  position: absolute;
  left: 1px;
  bottom: -1px;
  height: 86%;
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.AccountVerifyPanelUlWrapper > ul > li > .AccountVerifyItemBody > div > span {

  user-select: none;

}


.AccountVerifyPanelUlWrapper > ul > li > .AccountVerifyItemBody > input {

  height: 90%;
  width: 100%;
  outline: none;
  border-radius: 10px;
  margin-top: 10px;
  text-align: right;
  direction: rtl;
  padding: 10px;
  padding-left: 60px;

}

.AccountVerifyPanelUlWrapper > ul > li > .AccountVerifyItemBody > span {

  float: right;
  font-weight: 700;
  text-align: right;
  height: 100%;
  width: 60px;
  border-radius: 10px 0 0 10px;

}

.AccountVerifyItemHeader {
  
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding-right: 10px;

}

.AccountVerifyUploadDocuments {

  width: 100%;
  height: 100%;

}

.AccountVerifyUploadDocuments > ul {

  width: 100%;
  height: 90%;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;

}

.AccountVerifyUploadDocuments > ul > li {

  width: 140px;
  height: 190px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

}

.AccountVerifyUploadDocuments > ul > li > div {

  width: 100%;
  height: 70%;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.AccountVerifyUploadDocuments > ul > li > div > .MuiSvgIcon-root {

  width: 50%;
  height: 50%;

}

.AccountVerifyUploadDocuments > ul > li > span {

  font-weight: 700;

}

.AccountVerifyUploadQualifyPageWrapper {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

}

.AccountVerifyUploadQualifyPage {

  width: 300px;
  height: 80%;
  padding: 24px;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;

}

.AccountVerifyUploadQualifySmapleImage {

  width: 300px;
  height: 60%;
  background-color: rgb(240, 240, 240);
  border-radius: 10px;

}

.StepHeader {

  width: 60%;
  text-align: center;
  font-weight: 700;

}

.AccountVerifyUploadQualifySmapleText {

  text-justify: inter-word;

}

.AccountVerifyStepButtonsSubmit {

  width: 70%;
  height: 60px;
  background-color: rgb(49, 117, 241);
  border-radius: 10px;
  color: white !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  transition: background-color 200ms;
  user-select: none;

}

.AccountVerifyStepButtonsSubmitDisabled {

  width: 70%;
  height: 60px;
  background-color: rgb(179, 179, 179);
  border-radius: 10px;
  color: white !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  cursor: not-allowed;
  transition: background-color 200ms;
  pointer-events: none;
  user-select: none;

}

.AccountVerifyStepButtonsSubmitLogin {

  width: 70%;
  height: 60px;
  background-color: #00A652;
  border-radius: 10px;
  color: white !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  transition: background-color 200ms;

}

.AccountVerifyStepButtonsSubmitLogin:hover {

  opacity: .7;

}

.AccountVerifyStepButtonsSubmit:hover {

  opacity: .7;

}

.IdCardUploadWrapper {

  width: 100%;
  height: 100%;

}

.IdCardUploadWrapper > ul {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  list-style: none;

}

.IdCardUploadWrapper > ul > li {

  width: 70%;
  height: 45%;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 200ms;

}

.IdCardUploadWrapper > ul > li > .MuiSvgIcon-root { 

  width: 3rem;
  height: 3rem;

}

.AccountVerifyUploadQualifyPageSubmit {

  width: 100%;
  height: 100px;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 200ms;
  position: relative;

}

.AccountVerifyUploadQualifyPageSubmit > .MuiSvgIcon-root { 

  width: 3rem;
  height: 3rem;

}

.AccountVerifyUploadQualifyPageContainer {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;

}

.AccountVerifyUloadSample {

  width: 300px;
  height: 80%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;

}

.AccountVerifyStepFinaly {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

}

.SupportWrapper {

  width: 100%;
  height: 100%;

}

.SupportTickets {

  width: 100%;
  height: 100%;
  border-radius: 20px;

}

.SupportTicketsHeader {

  width: 100%;
  height: 70px;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

}

.SupportTicketsHeaderTitle {

  font-weight: 700;
  margin-right: 30px;
  
}

.NewTicket {

  width: 120px;
  height: 35px;
  background-color: #00A652;
  margin-left: 30px;
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  transition: box-shadow 200ms;

}

.NewTicket > span {

  color: white !important;
  font-weight: 300;

}

.NewTicket > .MuiSvgIcon-root {

  fill: white;

}

.NewTicketWrapper {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;

}

@media only screen and (max-width: 1340px) {

  .NewTicketWrapper {

    height: auto;

  }

}

.NewTicketPanel {

  position: relative;
  width: 50%;
  max-width: 600px;
  height: 98%;
  max-height: 800px;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  min-height: 500px; 

}

@media only screen and (max-width: 1340px) {

  .NewTicketPanel {

    width: 100%;
    height: 100%;
    box-shadow: unset;
    border: unset !important;
    padding: unset;

  }

}

.NewTicketHeader {

  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;

}

.NewTicketHeader > span {

  font-weight: 700;

}

.NewTicketHeader > ul {

  width: 85%;
  height: 60%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  align-items: center;
  list-style: none;
  margin-top: 10px;

}

@media only screen and (max-width: 1340px) {

  .NewTicketHeader > ul {

    width: 90%;
    height: 80%;

  }

}

.NewTicketHeader > ul > a > li {

  width: 100px;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 200ms;

}

.NewTicketHeader > ul > a {

  width: 100px;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 200ms;

}

.NewTicketHeader > ul > a > li > .MuiSvgIcon-root {

  width: 2.3rem;
  height: 2.3rem;
  margin: unset !important;

}

@media only screen and (max-width: 1340px) {

  .NewTicketHeader > ul > a > li > .MuiSvgIcon-root {

    width: 2.1rem;
    height: 2.1rem;

  }

}

.NewTicketPanelUlWrapper {
  
  width: 100%;
  height: auto;

}

.NewTicketPanelUlWrapper > ul {

  width: 100%;
  height: 450px;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: safe;
  align-items: center;
  margin-top: 10px;

}

.NewTicketPanelUlWrapper > ul > li {

  width: 70%;
  height: 400px;
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

}

@media only screen and (max-width: 1340px) {

  .NewTicketPanelUlWrapper > ul > li {

    width: 90%;

  }

}

.NewTicketPanelUlWrapper > ul > li > .NewTicketItemBody {

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row ;

}

.NewTicketPanelUlWrapper > ul > li > .NewTicketItemBody > div {

  width: 60px;
  position: absolute;
  left: 1px;
  bottom: -1px;
  height: 86%;
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.NewTicketPanelUlWrapper > ul > li > .NewTicketItemBody > div > span {

  user-select: none;

}


.NewTicketPanelUlWrapper > ul > li > .NewTicketItemBody > input {

  height: 100%;
  width: 100%;
  outline: none;
  border-radius: 10px;
  margin-top: 10px;
  text-align: right;
  direction: rtl;
  padding: 10px;
  padding-left: 60px;

}

.NewTicketPanelUlWrapper > ul > li > .NewTicketItemBody > textarea {

  width: 100%;
  outline: none;
  border-radius: 10px;
  margin-top: 10px;
  text-align: right;
  direction: rtl;
  padding: 10px;
  padding-left: 60px;
  resize: none;

}

.NewTicketPanelUlWrapper > ul > li > .NewTicketItemBody > span {

  float: right;
  font-weight: 700;
  text-align: right;
  height: 100%;
  width: 60px;
  border-radius: 10px 0 0 10px;

}

.NewTicketItemHeader {
  
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;


} 

.NewTicketFileUpload {

  width: 100%;
  height: 70px;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 200ms;
  position: relative;

}

.NewTicketFileUpload > .MuiSvgIcon-root { 

  width: 2.5rem;
  height: 2.5rem;

}

.NewTicketFileUpload > span {

  
  font-weight: 500;

}

.NewTicketPanelSubmit {

  width: 70%;
  height: 60px;
  background-color: #3E8CCB;
  border-radius: 7px;
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;
  transition: 200ms;

}

@media only screen and (max-width: 1340px) {

  .NewTicketPanelSubmit  {

    width: 90%;

  }

}

.NewTicketPanelSubmit:hover {

  opacity: .7;

}

.NewTicketPanelSubmit > span {

  color: white !important;

}

.ShowTicketWrapper {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;

}

@media only screen and (max-width: 1340px) {

  .ShowTicketWrapper {

    height: auto;

  }

}

.ShowTicketPanel {

  position: relative;
  width: 50%;
  max-width: 600px;
  height: 85%;
  max-height: 700px;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding: 10px;

}

@media only screen and (max-width: 1340px) {

  .ShowTicketPanel {

    width: 100%;
    height: auto;
    border: unset !important;
    background-color: unset !important;
    border-radius: unset !important;

  }

}

.ShowTicketHeader {

  width: 100%;
  height: 17%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  
}

.ShowTicketHeader > .MuiSvgIcon-root {

  width: 3.5rem;
  height: 3.5rem;

}

.ShowTicketHeader > span {

  font-weight: 700;

}

.ShowTicketPanelUlWrapper {

  width: 100%;
  height: 90%;

}

.ShowTicketPanelUlWrapper > ul {

  width: 100%;
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

}

.ShowTicketPanelUlWrapper > ul > li {

  width: 90%;
  height: auto;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row-reverse;
  align-items: center;
  padding: 5px;

}

.ShowTicketPanelLiTitle {

  height: 100%;
  width: 20%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

}

.ShowTicketPanelLiTitle > span {

  font-weight: 700;

}

.ShowTicketPanelLiDescription {

  height: 97%;
  width: 78%;
  background-color: rgb(233, 233, 233);
  border: 4px rgb(255, 255, 255) solid;
  display: flex;
  align-items: center;
  justify-content: right;
  flex-direction: row;
  padding: 10px;
  border-radius: 15px 0 0 15px;

}

.ShowTicketPanelLiDescription > span {

  width: 100%;
  text-align: right;

}

.ReplyTicket {

  width: 120px;
  height: 35px;
  background-color: #00A652;
  margin-left: 30px;
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  transition: box-shadow 200ms;

}

.ReplyTicket > span {

  color: white !important;
  font-weight: 300;

}

.ReplyTicket > .MuiSvgIcon-root {

  fill: white;

}

.ReplyTicketWrapper {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;

}

.ReplyTicketPanel {

  position: relative;
  width: 40%;
  max-width: 600px;
  height: 80%;
  max-height: 800px;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding: 10px;

}

@media only screen and (max-width: 1340px) {

  .ReplyTicketPanel {

    width: 90%;
    height: auto;

  }

}

.ReplyTicketHeader {

  width: 100%;
  height: 3%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

}

.ReplyTicketHeader > span {

  font-weight: 700;

}

.ReplyTicketPanelUlWrapper {
  
  width: 100%;
  height: 90%;

}

@media only screen and (max-width: 1340px) {

  .ReplyTicketPanelUlWrapper {

    height: 400px;

  }

}

.ReplyTicketPanelUlWrapper > ul {

  width: 100%;
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: safe;
  align-items: center;
  margin-top: 10px;

}

.ReplyTicketPanelUlWrapper > ul > li {

  width: 70%;
  height: 400px;
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;


}

.ReplyTicketPanelUlWrapper > ul > li > .ReplyTicketItemBody {

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row ;

}

.ReplyTicketPanelUlWrapper > ul > li > .ReplyTicketItemBody > div {

  width: 60px;
  position: absolute;
  left: 1px;
  bottom: -1px;
  height: 86%;
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.ReplyTicketPanelUlWrapper > ul > li > .ReplyTicketItemBody > div > span {

  user-select: none;

}


.ReplyTicketPanelUlWrapper > ul > li > .ReplyTicketItemBody > input {

  height: 100%;
  width: 100%;
  outline: none;
  border-radius: 10px;
  margin-top: 10px;
  text-align: right;
  direction: rtl;
  padding: 10px;
  padding-left: 60px;

}

.ReplyTicketPanelUlWrapper > ul > li > .ReplyTicketItemBody > textarea {

  width: 100%;
  outline: none;
  border-radius: 10px;
  margin-top: 10px;
  text-align: right;
  direction: rtl;
  padding: 10px;
  padding-left: 60px;
  resize: none;

}

.ReplyTicketPanelUlWrapper > ul > li > .ReplyTicketItemBody > span {

  float: right;
  font-weight: 700;
  text-align: right;
  height: 100%;
  width: 60px;
  border-radius: 10px 0 0 10px;

}

.ReplyTicketItemHeader {
  
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;

} 

.ReplyTicketFileUpload {

  width: 100%;
  height: 70px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.103);
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 200ms;
  position: relative;

}

.ReplyTicketFileUpload > .MuiSvgIcon-root { 

  width: 2.5rem;
  height: 2.5rem;

}

.ReplyTicketFileUpload > span {

  
  font-weight: 500;

}

.ReplyTicketPanelSubmit {

  width: 70%;
  height: 60px;
  background-color: #3E8CCB;
  border-radius: 7px;
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;
  transition: 200ms;

}

.ReplyTicketPanelSubmit:hover {

  opacity: .7;

}

.ReplyTicketPanelSubmit > span {

  color: white !important;

}

.SidebarWrapper > .side-navigation-panel {

  direction: rtl !important;
  margin-top: 30px;

}

.SidebarWrapper > .side-navigation-panel > ul {

  margin-top: 0vh;

}

.SidebarWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap > .side-navigation-panel-select-option-selected:hover {

  border: unset;

}


.SidebarWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap > .side-navigation-panel-select-option > .side-navigation-panel-select-option-selected {

  background-color: red;

}

.SidebarWrapper > .side-navigation-panel > .side-navigation-panel-select-option > .side-navigation-panel-select-option-selected {

  background-color: red !important;

}

.SidebarWrapper > .side-navigation-panel > .side-navigation-panel {

  border-color: transparent;


}
  
.SidebarWrapper > .side-navigation-panel > .SidebarWrapper > nav > ul > li > div {

  border: unset;

}

.SidebarWrapper > .side-navigation-panel > .SidebarWrapper > nav {

  margin-top: 30px;
  width: 100% !important;

}

.SidebarWrapper > .side-navigation-panel > .side-navigation-panel-select-option-selected {

  position: relative;
  width: 100%;
  height: 40px;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 30px;

}

.SidebarWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap > .side-navigation-panel-select-option > .side-navigation-panel-select-option-wrap > .side-navigation-panel-select-option-text {

  color: #385894;
  width: 100%;
  font-weight: 500 !important;
  text-align: right !important;
  direction: ltr !important;
  margin-right: 10px;

}

.SidebarWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap >  .side-navigation-panel-select-option  {

  position: relative;
  width: 100%;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-radius: 10px;
  margin-top: 0px;

}

.SidebarWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-inner > .side-navigation-panel-select-inner-wrap > .side-navigation-panel-select-inner-option  {

  border-left-width: unset !important;

}

.SidebarWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-inner > .side-navigation-panel-select-inner-wrap > .side-navigation-panel-select-inner-option > .side-navigation-panel-select-inner-option-wrap > .side-navigation-panel-select-inner-option-text {

  color: #385894 ;
  font-weight: 700;

}

.side-navigation-panel-select-inner-option-wrap > svg {

  width: 1.5rem !important;
  height: 1.5rem !important;

}

.SecurityWrapper {

  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.SecurityWrapper > ul {

  width: 100%;
  height: 100%;
  list-style: none;
  display: flex;
justify-content: flex-end;
flex-direction: row;
align-items: center;
align-content: center;
flex-wrap: wrap;

}

.SecurityWrapper > ul > li {

  width: 600px;
  height: 300px;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 20px;

}

@media only screen and (max-width: 1340px) {

  .SecurityWrapper > ul > li  {

    margin: 10px;

  }

}

.SecurityLiHeader {

  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;

}

@media only screen and (max-width: 1340px) {

  .SecurityLiHeader {

    justify-content: space-evenly;

  }

}

.SecurityLiHeader > h3 {

  margin-right: 30px;
  font-weight: 700;

}

@media only screen and (max-width: 1340px) {

  .SecurityLiHeader > span {

    text-align: right;
    margin-right: unset;

  }

}

.SecurityLiHeaderAction {

  width: auto;
  padding: 0 10px;
  height: 30px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  margin-left: 30px;

}

@media only screen and (max-width: 1340px) {

  .SecurityLiHeaderAction {

    display: none;

  }

}

.SecurityLiHeaderAction > span {

  font-weight: 700;

}

.SecurityLiHeaderAction > .MuiSvgIcon-root {

  width: 1.2rem;
  height: 1.2rem;
  margin-right: 5px;

}

.SecurityContainer {

  width: 100%;
  height: 45%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-end;
  padding: 24px;

}

@media only screen and (max-width: 1340px) {

  .SecurityContainer {

    align-items: center;

  }

}

.SecurityContainer > .MuiSvgIcon-root {

  width: 3.4rem;
  height: 3.4rem;

}

@media only screen and (max-width: 1340px) {

  .SecurityContainer > .MuiSvgIcon-root  {

    width: 3.1rem;
    height: 3.1rem;

  }

}

.SecurityContainer > .SecurityTitle {

  font-weight: 700;

}

.SecurityContainer > .SecurityDescription {

  font-weight: 500;
  text-align: right;

}

@media only screen and (max-width: 1340px) {

  .SecurityContainer > .SecurityDescription  {

    text-align: center;

  }

}

.SecurityAction {

  width: 100%;
  height: 20%;
  display: flex;
  justify-content: safe;
  align-items: center;
  padding: 20px;

}

@media only screen and (max-width: 1340px) {

  .SecurityAction  {

    justify-content: space-around;

  }

}

.SecurityActionCall {

  width: 250px;
  height: 40px;
  background-color: #3E8CCB;
  border-radius: 7px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  margin-left: 15px;

}

.SecurityActionCall > a > span {

  color: white !important;

}

.SecurityActionCall > span {

  color: white !important;

}

.LoginHistory {

  width: 100%;
  height: 100%;
  border-radius: 20px;

}

.LoginHistoryHeader {

  width: 100%;
  height: 70px;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

}

.LoginHistoryHeaderTitle {

  font-weight: 700;
  margin-right: 30px;
  
}

.AuthenticationPhoneWrapper {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

@media only screen and (max-width: 1340px) {

  .AuthenticationPhoneWrapper {

    height: 90vh;
    width: 100%;

  }

}

.AuthenticationPhonePanel {

  width: 40%;
  height: 53vh;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  position: relative;

}

@media only screen and (max-width: 1340px) {

  .AuthenticationPhonePanel {

    width: 100%;
    padding: 20px;
    height: 45vh;

  }

}

.AuthenticationPhonePanel > .MuiSvgIcon-root {

  width: 5rem;
  height: 5rem;

}

@media only screen and (max-width: 1340px) {

  .AuthenticationPhonePanel > .MuiSvgIcon-root {

    width: 4.5rem;
    height: 4.5rem;

  }

}

.AuthenticationTitle1 {

  font-weight: 700;
  margin-top: -24 px;

}

.AuthenticationTitle2 {

  font-weight: 400;
  width: 80%;
  text-align: center;

}


.AuthenticationTitle3 {

  font-weight: 400;
  width: 80%;
  text-align: center;
  margin-top: 25px;

}

.AuthenticationTitle4 {

  font-weight: 400;
  width: 80%;
  text-align: center;
  margin-top: 0px;
  color: gray;

}

.AuthenticationPhoneAction {

  width: 80%;
  height: 50px;
  background-color: #3E8CCA;
  border-radius: 7px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  transition: 200ms;

}

.AuthenticationPhoneAction:hover {

  opacity: .7;

}

.AuthenticationPhoneAction > span {

  color: white !important;

}

.react-code-input > input {

  width: 4rem !important;
  height: 4rem !important;
  text-align: center;
  padding: 0 !important;
  border: unset !important;
  outline: unset !important;
  user-select: none !important;

}

@media only screen and (max-width: 1340px) {

  .react-code-input > input {

    width: 2rem !important;
    height: 2rem !important;

  }

}

.react-code-input {

  padding: 10px 0;
  border-top: 1px #00a2ff26 solid;
  border-bottom: 1px #00a2ff26 solid;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.AuthenticationResend {

  width: 24%;
  height: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  margin: 5px 0;

}

@media only screen and (max-width: 1340px) {

  .AuthenticationResend {

    width: 50%;

  }

}

.AuthenticationAppWrapper {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}


@media only screen and (max-width: 1340px) {

  .AuthenticationAppWrapper {

    width: 100%;
    height: 100vh;

  }

}

.AuthenticationAppPanel {

  width: 50%;
  height: 80vh;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 20px;

}

@media only screen and (max-width: 1340px) {

  .AuthenticationAppPanel {

    width: 100%;
    height: auto;
    border-radius: 0;
    padding: 30px;
    background-color: transparent !important;
    border: unset !important;

  }

}

.AuthenticationAppPanel > ul {

  width: 100%;
  height: 150px;
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

}

@media only screen and (max-width: 1340px) {

  .AuthenticationAppPanel > ul {

    height: 100px;

  }

}

.AuthenticationAppPanel > ul > li {

  width: 80%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
  direction: ltr;

}

@media only screen and (max-width: 1340px) {

  .AuthenticationAppPanel > ul > li {

    width: 95%;

  }

}

.AuthenticationAppPanel > img {

  width: 5rem;

}

@media only screen and (max-width: 1340px) {

  .AuthenticationAppPanel > img {

    width: 5rem;

  }

}

.AuthenticatorAppLiNumber {

  width: 1.7rem;
  height: 1.7rem;
  border-radius: 10%;
  background-color: #3BC87C;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  text-align: right;

}

.AuthenticatorAppLiNumber > span {

  color: white !important;

}

.AuthenticatorAppLiText {

  width: 80%;
  font-weight: 700 !important;
  margin-right: 15px;
  text-align: right;

}

.AuthenticatorPhoneQrCode {

  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  align-items: center;
  flex-direction: row;
  position: relative;

}

@media only screen and (max-width: 1340px) {

  .AuthenticatorPhoneQrCode {

    width: 5rem;
    height: 5rem;
    margin: 24px;

  }

}

.AuthenticatorPhoneQrCode > img {

  width: 100%;

}

.AuthenticationPhoneTitle1 {

  font-weight: 700;

}

@media only screen and (max-width: 1340px) {

  .AuthenticationPhoneTitle1 {

    margin: 24px;

  }

}

.AuthenticatorAppItemBody {

  position: relative;
  width: 75%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row ;

}

@media only screen and (max-width: 1340px) {

  .AuthenticatorAppItemBody  {

    width: 100%;
    height: 60px;

  }

}

.AuthenticatorAppItemBody > div {

  width: 60px;
  position: absolute;
  left: 1px;
  bottom: -1px;
  height: 86%;
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.AuthenticatorAppItemBody > div > span {

  user-select: none;

}


.AuthenticatorAppItemBody > input {

  height: 90%;
  width: 100%;
  outline: none;
  border-radius: 10px;
  margin-top: 10px;
  text-align: right;
  direction: rtl;
  padding-left: 60px;
  font-weight: 500;
  text-align: center;
  color: rgb(68, 68, 68);

}

.AuthenticatorAppItemBody > span {

  float: right;
  font-weight: 700;
  text-align: right;
  height: 100%;
  width: 60px;
  border-radius: 10px 0 0 10px;

}

.AuthenticationPanelSubmit {

  width: 70%;
  height: 50px;
  background-color: #3E8CCB;
  border-radius: 7px;
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;
  transition: 200ms;

}

@media only screen and (max-width: 1340px) {

  .AuthenticationPanelSubmit {

    margin: 20px;
    width: 100%;

  }

}

.AuthenticationPanelSubmit:hover {

  opacity: .7;

}

.AuthenticationPanelSubmit > span {

  color: white !important;

}

.ChangePasswordWrapper {

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;

}

@media only screen and (max-width: 1340px) {

  .ChangePasswordWrapper  {

    height: auto;

  }

}

.ChangePasswordPanel {

  width: 40%;
  height: 80vh;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  position: relative;

}

@media only screen and (max-width: 1340px) {

  .ChangePasswordPanel  {

    width: 100%;
    height: 100%;
    box-shadow: unset;
    border-radius: unset;
    padding: 24px;

  }

}

.ChangePasswordPanel > .MuiSvgIcon-root {

  width: 6rem;
  height: 6rem;
  margin-top: 70px;

}

.ChangePasswordPanelHeaderText {

  font-weight: 700;
  margin-top: 5px;

}

.ChangePasswordPanelHeaderDescription {

  font-weight: 700;
  margin-top: 10px;

}

.ChangePasswordPanelUlWrapper {
  
  width: 100%;
  height: 350px;

}

.ChangePasswordPanelUlWrapper > ul {

  width: 100%;
  height: 90%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: safe;
  align-items: center;
  margin-top: 10px;

}

.ChangePasswordPanelUlWrapper > ul > li {

  width: 70%;
  height: 80px;
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

}

.ChangePasswordPanelUlWrapper > ul > li > .ChangePasswordItemBody {

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row ;

}

.ChangePasswordPanelUlWrapper > ul > li > .ChangePasswordItemBody > div {

  width: 60px;
  position: absolute;
  left: 1px;
  bottom: -1px;
  height: 86%;
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.ChangePasswordPanelUlWrapper > ul > li > .ChangePasswordItemBody > div > span {

  user-select: none;

}


.ChangePasswordPanelUlWrapper > ul > li > .ChangePasswordItemBody > input {

  height: 90%;
  width: 100%;
  outline: none;
  border-radius: 10px;
  margin-top: 10px;
  text-align: right;
  direction: rtl;
  padding: 10px;
  padding-left: 60px;

}

.ChangePasswordPanelUlWrapper > ul > li > .ChangePasswordItemBody > span {

  float: right;
  font-weight: 700;
  text-align: right;
  height: 100%;
  width: 60px;
  border-radius: 10px 0 0 10px;

}

.ChangePasswordItemHeader {
  
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 100%;

}

.BankAccountSubmit {

  width: 70%;
  height: 50px;
  background-color: #3e8ccb;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  margin: 24px;

}

.BankAccountSubmit > span {

  color: white !important;

}

.ChangePasswordPanelClose {

  position: absolute;
  top: 24px;
  right: 24px;
  width: 2rem !important;
  height: 2rem !important;

}

.ChangePasswordPanelWrapper {

  position: absolute;

}

.ChangePasswordEmailWrapper {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.ChangePasswordEmailPanel {

  width: 40%;
  height: 53vh;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  position: relative;

}

.ChangePasswordEmailPanel > .MuiSvgIcon-root {

  width: 5rem;
  height: 5rem;

}

.AuthenticationTitle1 {

  font-weight: 700;
  margin-top: -24 px;

}

.AuthenticationTitle2 {

  font-weight: 400;
  width: 80%;
  text-align: center;

}


.AuthenticationTitle3 {

  font-weight: 400;
  width: 80%;
  text-align: center;
  margin-top: 25px;

}

.ChangePasswordEmailAction {

  width: 80%;
  height: 50px;
  background-color: #3E8CCA;
  border-radius: 7px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  transition: 200ms;

}

.ChangePasswordEmailAction:hover {

  opacity: .7;

}

.ChangePasswordEmailAction > span {

  color: white !important;

}

.ChangePasswordEmailItemBody {

  position: relative;
  width: 80%;
  height: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row ;

}

.ChangePasswordEmailItemBody > div {

  width: 60px;
  position: absolute;
  left: 1px;
  bottom: -1px;
  height: 86%;
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.ChangePasswordEmailItemBody > div > span {

  user-select: none;

}


.ChangePasswordEmailItemBody > input {

  height: 90%;
  width: 100%;
  outline: none;
  border-radius: 10px;
  margin-top: 10px;
  text-align: left;
  direction: ltr;
  padding: 10px;
  padding-left: 60px;

}

.ChangePasswordEmailItemBody > span {

  float: right;
  font-weight: 700;
  text-align: right;
  height: 100%;
  width: 60px;
  border-radius: 10px 0 0 10px;

}

.ChangePasswordEmailItem > .ChangePasswordEmailItemBody {

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row ;

}

.ChangePasswordEmailItem > .ChangePasswordEmailItemBody > div {

  width: 60px;
  position: absolute;
  left: 1px;
  bottom: -1px;
  height: 90%;
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.ChangePasswordEmailItem > .ChangePasswordEmailItemBody > div > span {

  user-select: none;

}


.ChangePasswordEmailItem > .ChangePasswordEmailItemBody > input {

  height: 90%;
  width: 100%;
  outline: none;
  border-radius: 10px;
  margin-top: 10px;
  text-align: right;
  direction: rtl;
  padding: 10px;

}

.ChangePasswordEmailItem > .ChangePasswordEmailItemBody > span {

  float: right;
  font-weight: 700;
  text-align: right;
  height: 100%;
  width: 60px;
  border-radius: 10px 0 0 10px;

}

.ChangePasswordEmailItemHeader {
  
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  width: 100%;

}

.ChangePasswordEmailItem {

  width: 80%;
  height: 80px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

}

.Orders {

  width: 100%;
  height: 100%;
  border-radius: 20px;
  float: right;
  margin-top: 0;
  float: right;

}

.DepositRialWrapper > .Orders {

  margin-top: 20px;

}

.DashboardWrapper > .Orders {

  margin-top: 20px;
  width: 100%;
  border-radius: 10px;

}

.AccountUpgrade {

  margin-top: -30px;
  font-weight: 700;
  cursor: pointer;
  
}

.OrdersHeader {

  width: 100%;
  height: 70px;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

}

.OrdersHeaderTitle {

  font-weight: 700;
  margin-right: 30px;
  
}

.HeaderButton {

  width: auto;
  height: 30px;
  margin-left: 30px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  transition: 200ms;
  padding: 5px 15px;

}

@media only screen and (max-width: 1340px) {

  .HeaderButton {

    height: 30px;
    padding-right: 2px;
    padding-top: 10px;
    padding-bottom: 10px;

  }

}

.HeaderButton:hover {

  opacity: .7;

} 

.HeaderButton > span {

  color: white !important;
  font-weight: 400;
  height: auto;
  direction: rtl;
  text-align: right;
  text-justify: inter-word;
  display: inline;

}

@media only screen and (max-width: 1340px) {

  .HeaderButton > span {

    display: none;

  }

}

.HeaderButton > .MuiSvgIcon-root {

  fill: white;

}

@media only screen and (max-width: 1340px) {

  .HeaderButton > .MuiSvgIcon-root {

    width: 1.2rem;
    height: 1.2rem;

  }

}

.HeaderButton2 {

  width: auto;
  height: 35px;
  margin-left: 30px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: 200ms;
  padding: 5px 10px;

}

@media only screen and (max-width: 1340px) {

  .HeaderButton2 {

    height: 30px;
    padding-right: 2px;
    padding-top: 10px;
    padding-bottom: 10px;

  }

}

.HeaderButton2:hover {

  opacity: .7;

} 

.HeaderButton2 > span {

  color: white !important;
  font-weight: 400;
  height: auto;
  direction: rtl;
  text-align: right;
  text-justify: inter-word;
  display: inline;

}

@media only screen and (max-width: 1340px) {

  .HeaderButton2 > span {

    display: none;

  }

}

.HeaderButton2 > .MuiSvgIcon-root {

  fill: white;
  margin-right: 10px;

}

@media only screen and (max-width: 1340px) {

  .HeaderButton2 > .MuiSvgIcon-root {

    width: 1.2rem;
    height: 1.2rem;

  }

}

.RefferalCodeWrapper {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.HeaderButton3 {

  width: auto;
  height: 35px;
  margin-left: 30px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  transition: 200ms;
  padding: 5px 10px;

}

.HeaderButton3:hover {

  opacity: .7;

} 

.HeaderButton3 > span {

  color: white !important;
  font-weight: 400;
  height: auto;
  direction: rtl;
  text-align: right;
  text-justify: inter-word;
  display: inline;

}

.HeaderButton3 > .MuiSvgIcon-root {

  fill: white;
  margin-right: 10px;

}

.RefferalCodeWrapper {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

@media only screen and (max-width: 1340px) {

  .RefferalCodeWrapper {

    height: auto;

  }

}

.RefferalCodePanel {

  width: 50%;
  height: 80vh;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 20px;

}

@media only screen and (max-width: 1340px) {

  .RefferalCodePanel {

    width: 100%;
    height: auto;
    border-radius: unset !important;
    background-color: unset !important;

  }

}

.RefferalCodePanel > ul {

  width: 100%;
  height: 150px;
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

}

.RefferalCodePanel > ul > li {

  width: 80%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
  direction: ltr;

}

.RefferalCodePanel > .MuiSvgIcon-root {

  width: 6rem;
  height: 6rem;

}

@media only screen and (max-width: 1340px) {

  .RefferalCodePanel > .MuiSvgIcon-root {

    width: 4.5rem;
    height: 4.5rem;

  }

}

.RefferalCodeLiNumber {

  width: 1.7rem;
  height: 1.7rem;
  border-radius: 10%;
  background-color: #3BC87C;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  text-align: right;

}

.RefferalCodeLiNumber > span {

  color: white !important;

}

.RefferalCodeLiText {

  width: 80%;
  font-weight: 700 !important;
  margin-right: 15px;
  text-align: right;

}

.AuthenticatorPhoneQrCode {

  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  align-items: center;
  flex-direction: row;
  position: relative;

}

.AuthenticatorPhoneQrCode > img {

  width: 100%;

}

.RefferalCodeItemBody {

  position: relative;
  width: 75%;
  height: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row ;

}

@media only screen and (max-width: 1340px) {

  .RefferalCodeItemBody {

    height: 100px !important;

  }

}

.RefferalCodeItemBody > div {

  width: 60px;
  position: absolute;
  left: 1px;
  bottom: -1px;
  height: 86%;
  border-radius: 10px 0 0 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.RefferalCodeItemBody > div > span {

  user-select: none;

}


.RefferalCodeItemBody > input {

  height: 90%;
  width: 100%;
  outline: none;
  border-radius: 10px;
  margin-top: 10px;
  text-align: right;
  direction: rtl;
  padding-left: 60px;
  font-weight: 500;
  text-align: center;
  color: rgb(68, 68, 68);

}

.RefferalCodeItemBody > span {

  float: right;
  font-weight: 700;
  text-align: right;
  height: 100%;
  width: 60px;
  border-radius: 10px 0 0 10px;

}

.RefferalCodeItemBodyHeader {

  width: 70%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

}

.RefferalCodeItemBodyHeader > span {

  margin-right: 10px;
  font-weight: 700;

}

.RefferalCodeItemContainer {

  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;

}

@media only screen and (max-width: 1340px) {

  .RefferalCodeItemContainer {

    height: 70px;
    margin: 24px;

  }

}

.RefferalCodePanelSubmit {

  width: 70%;
  height: 50px;
  background-color: #3E8CCB;
  border-radius: 7px;
  display: flex;
  justify-content: space-around;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;
  transition: 200ms;

}

.RefferalCodePanelSubmit:hover {

  background-color: #8aadca;

}

.RefferalCodePanelSubmit > a > span {

  color: white !important;

}

.RefferalCodeWrapper {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

}

.RefferalCode {

  width: 100%;
  height: 40vh;
  border-radius: 20px;
  margin-bottom: 20px;

}

.RefferalCodeHeader {

  width: 100%;
  height: 70px;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

}

.RefferalCodeHeaderTitle {

  font-weight: 700;
  margin-right: 30px;
  
}

.RefferalCodeButton {

  width: auto;
  height: 35px;
  background-color: #00A652;
  margin-left: 30px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  padding: 0 20px;
  transition: box-shadow 200ms;

}

.RefferalCodeButton > span {

  color: white !important;
  font-weight: 300;

}

.RefferalCodeButton > .MuiSvgIcon-root {

  fill: white;
  margin-right: 10px;

}

.PurificationWrapper {

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.PurificationPanel {

  width: 40%;
  height: 53vh;
  border-radius: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  position: relative;

}

@media only screen and (max-width: 1340px) {

  .PurificationPanel {

    width: 100%;
    border: unset !important;
    border-radius: unset !important;
    background-color: unset !important;

  }

}

.PurificationPanel > .MuiSvgIcon-root {

  width: 5rem;
  height: 5rem;

}

.AuthenticationTitle1 {

  font-weight: 700;
  margin-top: -24 px;

}

.AuthenticationTitle2 {

  font-weight: 400;
  width: 80%;
  text-align: center;

}


.AuthenticationTitle3 {

  font-weight: 400;
  
  width: 80%;
  text-align: center;
  margin-top: 25px;

}

.AuthenticationTitle4 {

  font-weight: 400;
  width: 80%;
  text-align: center;
  margin-top: 0px;
  color: gray;

}

.PurificationAction {

  width: 350px;
  height: 50px;
  background-color: #3E8CCA;
  border-radius: 7px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  transition: 200ms;

}

.PurificationAction:hover {

  background-color: #88b6db;

}

.PurificationAction > span {

  color: white !important;

}

.PurificationPriceContainer {
  
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

}

.PurificationPriceContainer > span {

  font-weight: 700;

}

.PurificationPriceContainer > input {

  width: 70%;
  height: 50px;
  border-radius: 7px;
  outline: none;
  text-align: center;

}

.AuthenticationTitle5 {

  margin-top: -25px;

}

.SignUpWrapper {

  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-flow: wrap; 
}

@media only screen and (max-width: 1340px) {

  .SignUpWrapper {

    overflow: auto;

  }

}

.SignUpContainer {
  
  width: 50vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;

}

.SignUpInner {

  width: 90%;
  height: auto;

}

@media only screen and (max-width: 1340px) {

  .SignUpContainer {

    width: 100vw;

  }

}

.SignUpContainerHeader {

  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

}

.SignUpContainerHeader > .MuiSvgIcon-root {

  width: 4.5rem;
  height: 4.5rem;
  fill: rgb(177, 177, 177);
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.219);
  border-radius: 15px;
  padding: 10px;

}

.SignUpContainerHeader > span {

  font-weight: 500;
  margin-top: 10px;
  color: black;

}

.SignUpContainerBody {

  height: auto;
  margin-top: 4%;

}

.SignUpContainerChekckOut {

  height: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;

}

.SignUpContainerChekckOut > input {

  width: 1.2rem;
  height: 1.2rem;
  margin-left: 5px;

}

.SignUpContainerChekckOut > span {

  color:  black;

}

.SignUpContainerChekckOut > span > span {

  color:  rgb(10, 167, 10);
  cursor: pointer;

}

.SignUpSubmit {

  width: 80%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  background-color: rgb(40, 106, 230);
  margin-top: 24px;
  border-radius: 10px;
  cursor: pointer;

}

.SignUpSubmit > span {

  color: white !important;

}

.MuiTypography-root {

  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

}

.LoginContainer {
  
  width: 50vw;
  height: 100vh; 
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

@media only screen and (max-width: 1340px) {

  .LoginContainer {

    width: 100vw;

  }

}

.LoginPanel {

  width: 60%;
  height: auto;
  border-radius: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

}

@media only screen and (max-width: 1340px) {

  .LoginPanel {

    width: 95vw;

  }

}

.LoginPanel > img {

  width: 7rem;
  margin-top: 40px;

}

.LoginLogoText {

  position: relative;
  font-size: 2.4vw !important;
  font-weight: 800;
  color: #214185;

}

@media only screen and (max-width: 1340px) {

  .LoginLogoText {

    font-size: 27px !important;

  }

}

.SignUpContainerBodyUl {

  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

}


.SignUpContainerBodyUl > li{

  background-color: rgb(255, 255, 255);
  width: 80%;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  border-radius: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.158);
  margin: 10px;

}

.SignUpContainerBodyUl > li > input{

  width: 90%;
  border: unset;
  outline: unset;
  height: 90%;
  background-color: transparent;

}

.SignUpContainerBodyUl > li > .MuiSvgIcon-root{

  width: 1.9rem;
  height: 1.9rem;
  fill: rgba(128, 128, 128, 0.466);
  margin: 9px;

}

.LoginLogoText2 {

color: #4890cd;

}

.LoginDescription {

  font-weight: 700;
  text-align: center;
  margin: auto;

}

@media only screen and (max-width: 1340px) {

  .LoginDescription {

    margin-top: 0%;

  }

}

.LoginButton {

  width: 75%;
  height: 50px;
  background-color: rgb(8, 170, 75);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  border-radius: 30px;
  margin-top: 80px;
  cursor: pointer;

}

@media only screen and (max-width: 1340px) {

  .LoginButton {

    margin-top: 20px;

  }

}

.LoginButton > span {

  color: white !important;

}

.LoginGoogleButton {

  width: 75%;
  height: 50px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row-reverse;
  border-radius: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  margin-top: 20px;

}

@media only screen and (max-width: 1340px) {

  .LoginGoogleButton {

    margin-top: 20px;

  }

}

.LoginGoogleButton > img {

  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  border: 1px #0995c0 solid;
  padding: 5px;
  margin-right: -10%;

}

.LoginGoogleButton > span {

  color: #0995c0;

}

@media only screen and (max-width: 1340px) {

  .BankAddAccountPanelCloseWrapper {

    display: none;

  }

}

@media only screen and (max-width: 1340px) {

  .NewTicketPanel > a > .BankAddAccountPanelClose {

    display: none;

  }

}

.SidebarMobileWrapper > .side-navigation-panel {

  direction: rtl !important;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

}

.SidebarMobileWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap > .side-navigation-panel-select-option-selected {

  border: unset;
  background-color: red;

}

.SidebarMobileWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap > .side-navigation-panel-select-option-selected:hover {

  border: unset;

}

.SidebarMobileWrapper > .side-navigation-panel > .side-navigation-panel {

  border-color: transparent;

}


.SidebarMobileWrapper > .side-navigation-panel > .side-navigation-panel-select-option-selected {

  position: relative;
  width: 100%;
  height: 40px;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

.SidebarMobileWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap > .side-navigation-panel-select-option > .side-navigation-panel-select-option-wrap > .side-navigation-panel-select-option-text {

  color: #d4d4d4;
  width: 100%;
  font-weight: 400 !important;
  text-align: right !important;
  direction: ltr !important;

}

.SidebarWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap > .side-navigation-panel-select-option > .side-navigation-panel-select-option-wrap > .MuiSvgIcon-root {

  width: 1.5vw !important;
  height: 1.5vw !important;

}

@media only screen and (max-width: 1340px) {

  .SidebarMobileWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap > .side-navigation-panel-select-option > .side-navigation-panel-select-option-wrap > .MuiSvgIcon-root {

    width: 1.6rem !important;
    height: 1.6rem !important;

  }

}

.SidebarMobileWrapper > .side-navigation-panel > .side-navigation-panel-select  {

  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;

}

.SidebarMobileWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap  {

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 70%;

}

.SidebarMobileWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap > .side-navigation-panel-select-option > .side-navigation-panel-select-option-selected  {

  width: 100%;

}

.SidebarMobileWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap >  .side-navigation-panel-select-option  {

  position: relative;
  width: 100%;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
  border-bottom: 1px gray solid;

}

.side-navigation-panel-select-inner-wrap {


  padding : unset !important;

}

.SidebarMobileWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-inner > .side-navigation-panel-select-inner-wrap > .side-navigation-panel-select-inner-option  {

  border-left-width: unset !important;
  background-color: unset !important;


}

.side-navigation-panel-select-inner-option  {

  padding: .9rem 2rem !important;

}

.side-navigation-panel-select-inner-option > svg  {

  width: 4rem ;
  height: 4rem;

}

.SidebarMobileWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-inner > .side-navigation-panel-select-inner-wrap > .side-navigation-panel-select-inner-option > .side-navigation-panel-select-inner-option-wrap > .side-navigation-panel-select-inner-option-text {

  color: #d2dbec !important;

}

.SidebarIconMobile {

  fill: rgb(212, 212, 212) !important;
  color: white !important;
  width: 2rem !important;
  height: 2rem !important;

}

.side-navigation-panel-select-option > .side-navigation-panel-select-option-selected {

  background-color: transparent !important;

}

.SidebarMobileWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap > .side-navigation-panel-select-option > .side-navigation-panel-select-option-selected {

  background-color: transparent !important;

}

.side-navigation-panel-select-option-selected {

  background-color: transparent !important;

}

.side-navigation-panel-select-option  {

  border-left-width: 0px !important;

}

.WalletWrapper {

  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;

}

@media only screen and (max-width: 1340px) {

  .WalletWrapper {

    height: auto;
    border: unset !important;
    box-shadow: unset !important;
    background-color: unset !important;

  }

}

.Wallet {

  width: 100%;
  height: 100%;
  border-radius: 20px;

}

@media only screen and (max-width: 1340px) {

  .Wallet {

    height: auto;
    border: unset !important;
    box-shadow: unset !important;
    background-color: unset !important;

  }

}

.PanelHeader {

  width: 100%;
  height: 50px;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

}

.PanelHeaderTitle {

  font-weight: 700;
  margin-right: 30px;
  
}

.WalletContainer {

  width: 100%;
  height: 300px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

}

@media only screen and (max-width: 1340px) {

  .WalletContainer {

    height: auto;
    margin-bottom: 20px;

  }

}

.WalletConvertWrapper {

  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row-reverse;

}

@media only screen and (max-width: 1340px) {

  .WalletConvertWrapper {

    height: auto;
    justify-content: space-evenly;
    flex-flow: wrap;
    flex-direction: column-reverse;

  }

}

.WalletTableSearchWallet {

  width: 100%;
  height: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

}

@media only screen and (max-width: 1340px) {

  .WalletTableSearchWallet {

    justify-content: space-evenly;

  }

}

 .WalletTableSearchWrapper > input {

  width: 90%;
  height: 100%;
  outline: unset;
  direction: rtl;
  text-align: right;
  padding-right: 24px;
  border: unset;

}

@media only screen and (max-width: 1340px) {

  .WalletTableSearchWrapper {

    margin-right: unset !important;

  }

}

.WalletTableSearchWrapper {

  width: 350px;
  height: 40px;
  margin-right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  
}

.WalletIventoryConvertWrapper {

  height: 60%;
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

}

@media only screen and (max-width: 1340px) {

  .WalletIventoryConvertWrapper {

    width: 100%;
    padding: 12px;

  }

}

.WalletIventoryMaximumWrapper {

  height: 100%;
  width: 55%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;

}

@media only screen and (max-width: 1340px) {

  .WalletIventoryMaximumWrapper {

    width: 100%;
    height: 190px;

  }

}

.WalletIventoryConvertWrapperHeader {

  width: 100%;
  height: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

}

@media only screen and (max-width: 1340px) {

  .WalletIventoryConvertWrapperHeader {

    padding: 10px;

  }

}

.WalletIventoryConvertWrapperHeader >  .MuiSvgIcon-root {

  margin-right: 10px;

}

@media only screen and (max-width: 1340px) {

  .WalletIventoryConvertWrapperHeader > .MuiSvgIcon-root {

    width: 1.5rem;
    height: 1.5rem;
    margin-right: 5px;

  }

}

.WalletIventoryConvertWrapperHeader > span {

  margin-right: 24px;

}

@media only screen and (max-width: 1340px) {

  .WalletIventoryConvertWrapperHeader > span {

    margin-right: 18px;

  }

}

.WalletIventoryConvertWrapperBody {

  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;

}

.WalletIventoryConvertWrapperBody > div > input {

  width: 60%;
  height: 100%;
  text-align: center;
  font-weight: 400;
  border: none;
  outline: none;

}

.WalletIventoryConvertWrapperBody > div {

  width: 40%;
  height: 40px;
  border-radius: 15px;
  text-align: center;
  font-weight: 700;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;

}

@media only screen and (max-width: 1340px) {

  .WalletIventoryConvertWrapperBody > input {

    height: 40px;
    border-radius: 4px;

  }

}

.WalletChart {

  width: 20%;
  height: 15rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

}

@media only screen and (max-width: 1340px) {

  .WalletChart {

    width: 35%;

  }

}

.WalletIventoryMaximumWrapperLeft {

  width: 30%;
  height:30%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;

}

.WalletIventoryMaximumWrapperLeft > span {

  font-weight: 500;
  text-align: center;

}

.WalletIventoryMaximumWrapperRight {

  width: 30%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

}

.WalletIventoryMaximumWrapperRight > span {

  font-weight: 500;
  text-align: center;
  
}

.PanelHeaderActions {

  width: auto;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;

}

.HeaderButton > .MuiSvgIcon-root {

  margin-right: 10px !important;

}

.side-navigation-panel-select-inner-option {

  border: 0px !important;

}

.side-navigation-panel-select-inner-option {

  border-left-width: 0px !important;

}

.DepositRialWrapper {

  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

}

.DepositRialPanel {

  width: 100%;
  height: auto;
  border-radius: 20px;

}

@media only screen and (max-width: 1340px) {

  .DepositRialPanel {

    border: unset !important;
    background-color: unset !important;
    box-shadow: unset !important;

  }

}

.DepostiRialPanelTotal {

  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

}

.DepostiRialPanelTotalActions {

  width: 400px;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  flex-flow: wrap;

}

@media only screen and (max-width: 1340px) {

  .DepostiRialPanelTotalActions {

    width: 40%;

  }

}

.DepostiRialPanelTotalPrice {

  width: 400px;
  height: 100%;
  margin-right: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row-reverse !important;
  flex-flow: wrap;

}

@media only screen and (max-width: 1340px) {

  .DepostiRialPanelTotalPrice {

    width: 70%;
    margin-right: 0;

  }

}

.DepostiRialPanelTotalPrice > img {

  width: 3rem;

}

@media only screen and (max-width: 1340px) {

  .DepostiRialPanelTotalPrice > img {

    width: 2rem;

  }

}

.DepostiRialPanelTotalPrice > input {

  width: 200px;
  height: 40px;
  border-radius: 10px;
  text-align: center;
  direction: rtl;

}

@media only screen and (max-width: 1340px) {

  .DepostiRialPanelTotalPrice > input {

    width: 80%;
    height: 35px;

  }

}

.DepositRialPanelDescription {

  width: 100%;
  height: 340px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

}

@media only screen and (max-width: 1340px) {

  .DepositRialPanelDescription {

    height: auto;

  }

}

.DepostiPanelDescriptionHeader {

  width: 100%;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  flex-flow: wrap row;

}

@media only screen and (max-width: 1340px) {

  .DepostiPanelDescriptionHeader {

    height: 50px;

  }

}

.DepostiPanelDescriptionHeader > span {

  margin-right: 30px;
  text-align: right;
  direction: rtl;

}

.DepositRialPanelDescription > ul {

  width: 90%;
  height: auto;
  list-style: none;

}

.DepositRialPanelDescription > ul > li {

  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-start;
  margin-top: 10px;
  padding: 10px 0;

}

@media only screen and (max-width: 1340px) {

  .DepositRialPanelDescription > ul > li {

    height: auto;
    margin-top: 0px;

  }

}

.DepositRialPanelDescription > ul > li:nth-child(even) {

  background-color: rgba(167, 167, 167, 0.171);

}

.DepositRialPanelDescription > ul > li > .MuiSvgIcon-root {

  margin: 20px;
  fill: rgb(2, 173, 2);
  

}

.DepositRialPanelActions {

  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse !important;
  align-items: center;
  margin-top: 24px;
  flex-flow: wrap;

}

@media only screen and (max-width: 1340px) {

  .DepositRialPanelActions > a > .HeaderButton3 {

    width: 100% !important;
    margin-right: 10px;

  }

  .DepositRialPanelActions > a {

    width: 70% !important;

  }

}


@media only screen and (max-width: 1340px) {

  .DepositRialPanelActions {

    height: auto;
    justify-content: space-evenly;
    flex-direction: column !important;
    padding: 20px;

  }

}

.DepositRialPanelActionsItem {

  width: 350px;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin-right: 24px;
  flex-flow: wrap;

}

@media only screen and (max-width: 1340px) {

  .DepositRialPanelActionsItem {

    height: 100px;
    width: 100%;

  }

}

.DepositRialPanelActionsItemHeader {

  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

}

.DepositRialPanelActionsItemHeader > span {

  margin-right: 24px;

}

.DepositRialPanelActionsItemBody {

  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;

}

.DepositRialPanelActionsItemBody > input {

  width: 90%;
  height: 50%;
  direction: rtl;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
}

.DepositRialPanelSelectCard {

  width: 90%;
  height: 50%;
  direction: rtl;
  padding-right: 10px;
  padding-left: 10px;
  outline: none;
  border-radius: 10px;

}

.side-navigation-panel-select-option:hover {

  background-color: #d4e6f638 !important;

}

.side-navigation-panel-select-inner-option:hover {

  background-color: unset !important;

}



.MuiAvatar-root {

  width: 30px !important;
  height: 30px !important;
  margin: 4px !important;

}

.LoginWrapper {

  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;

}

.LoginPanelInner {

  width: 40vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  border-radius: 25px;
  padding: 20px;
    height: 90vh;
    max-height: 900px;

}

@media only screen and (max-width: 1340px) {

  .LoginPanelInner {

    padding: unset;
    height: auto;
    max-height: unset;

  }

}

.LoginPanelInnerHeader {

  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

}

.LoginPanelInner > .LoginPanelInnerHeader > img {

  width: 4rem;
  margin-top: 0px;

}

@media only screen and (max-width: 1340px) {

  .LoginPanelInner > .LoginPanelInnerHeader > img {

    width: 3vw;

  }

}

.LoginPanelLogoText {

  position: relative;
  font-size: 2vw !important;
  font-weight: 800;
  color: #214185;

}

.LoginPanelLogoText2 {

  position: relative;
  font-size: 2vw !important;
  font-weight: 800;
  color: #487beb;

}

@media only screen and (max-width: 1340px) {

  .LoginPanelLogoText {

    font-size: 27px !important;

  }

}

.LoginPanelInnerDescription {

  width: 85%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px rgba(0, 225, 255, 0.384) solid;
  flex-flow: wrap;

}

.LoginPanelInnerDescriptionHeader {

  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

}

.LoginPanelInnerDescriptionHeader > span {

  margin-right: 15%;
  font-weight: 700;
  color: black;
  padding-bottom: 2%;

}

.LoginPanelInnerDescriptionBody {

  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  flex-flow: wrap;
  direction: rtl;

}

.LoginPanelInnerDescriptionBody > span:nth-child(2) {

  color: rgb(4, 160, 207);
  background-color: white;
  border: 1px rgb(231, 231, 231) solid;
  border-radius: 30px;
  padding: 4px 10px;

}

.LoginContainerBodyUl {

  width: 90% !important;
  min-width: 300px;
  max-width: 600px;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

}

@media only screen and (max-width: 1340px) {

  .LoginContainerBodyUl {

    width: 70vw;

  }

}

.LoginContainerBodyUl > li{

  background-color: rgb(255, 255, 255);
  width: 90%;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.158);
  margin: 20px 10px; 

}

.LoginContainerBodyUl > li > input{

  width: 90%;
  border: unset;
  outline: unset;
  height: 90%;
  background-color: transparent;
  direction: rtl;
  padding-right: 20px;

}

.LoginContainerBodyUl > li > .MuiSvgIcon-root{

  width: 1.9rem;
  height: 1.9rem;
  fill: rgba(128, 128, 128, 0.466);
  margin: 9px;

}

.SignInContainerChekckOut {

  height: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 1%;

}

.SignInContainerChekckOut > input {

  width: 1.2rem;
  height: 1.2rem;
  margin-left: 10px;

}

.SignInContainerChekckOut > span {

  color:  black;

}

.SignInContainerChekckOut > span > span {

  color:  rgb(10, 167, 10);
  cursor: pointer;

}

.SignInButton {

  width: 80%;
  height: 60px;
  background-color: #00A652;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  transition: 200ms;
  padding: 1%;
  margin: 2%;

}

.SignInButton:hover {

  background-color: rgba(6, 158, 6, 0.603);

}

.SignInButton > span {

  color: white !important;

}

.SignInOtherActions {

  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px rgba(0, 225, 255, 0.384) solid;
  padding-bottom: 2%;
  padding-top: 1%;

}

.SignInOtherActions > span {

  font-weight: 700;
  cursor: pointer;

}

.SignInOtherActions > span:hover {

  opacity: .7;

}

.SignInGoogleButton {

  width: 75%;
  height: 70px;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row-reverse;
  border-radius: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  margin-top: 20px;

}

.SignInGoogleButton:hover {

  opacity: .8;

}

@media only screen and (max-width: 1340px) {

  .SignInGoogleButton {

    margin-top: 20px;

  }

}

.SignInGoogleButton > img {

  width: 2.9rem;
  height: 2.9rem;
  border-radius: 50%;
  border: 1px #0995c0 solid;
  padding: 5px;
  margin-right: -10%;

}

.SignInGoogleButton > span {

  color: #0995c0;

}

#HandShake {

  fill: #1E4282 !important;

}

#Refresh {

  fill: #1E4282 !important;

}

#Exchange {

  fill: #1E4282 !important;

}

#Layer_1 > line , polyline , polygon , path , ellipse , circle , ellipse {

  stroke: #1E4282 ;
  
}

ellipse {

  fill: #1E4282 ;


}

.MuiSvgIcon-root > path {

  stroke: transparent !important;

}

Line {

  fill: #204285 !important;
  stroke: #204285 !important;

}

.SupportSelect {

  background-color: #D6E6F4 !important;

}

.DashboardAccountInformation {

  width: auto;
  height: auto;
  border-radius: 10px;
  float: right;

}

.DashboardAccountInformation > ul {

  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  flex-flow: wrap;
  padding: 20px;
  list-style: none;
  direction: rtl;

}

.DashboardAccountInformation > ul > li {

  width: 40%;
  height: 50px;
  margin: 2px 4%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;

}

 .DashboardAccountInformation > ul > li > span:nth-child(1) {

  font-weight: 700;
  direction: rtl;

}

.DashboardAccountInformation > ul > li > span {

  text-align: center;
  color: black;

}

.DashboardAccountInformationUpgrade {

  text-align: right;
  float: right;
  font-weight: 500 !important;
  color: rgb(3, 184, 48) !important;
  cursor: pointer;

}

h2 {

  text-align: right;

}

span {

  text-align: right;
  direction: rtl;
  user-select: none;

}

/* Text Responsive */

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {

    h1 {
      font-size: 22px;
      font-weight: 800;
    }

    h2 {
      font-size: 19px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 12px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

    h1 {
      font-size: 22px;
      font-weight: 800;
    }

    h2 {
      font-size: 19px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 12px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

    h1 {
      font-size: 22px;
      font-weight: 800;
    }

    h2 {
      font-size: 19px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 12px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

    h1 {
      font-size: 22px;
      font-weight: 800;
    }

    h2 {
      font-size: 19px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 12px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

    h1 {
      font-size: 22px;
      font-weight: 800;
    }

    h2 {
      font-size: 19px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

    h1 {
      font-size: 22px;
      font-weight: 800;
    }

    h2 {
      font-size: 19px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 

    h1 {
      font-size: 22px;
      font-weight: 800;
    }

    h2 {
      font-size: 18px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 

    h1 {
      font-size: 22px;
      font-weight: 800;
    }

    h2 {
      font-size: 18px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 

    h1 {
      font-size: 22px;
      font-weight: 800;
    }

    h2 {
      font-size: 18px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

    h1 {
      font-size: 22px;
      font-weight: 800;
    }

    h2 {
      font-size: 19px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

    h1 {
      font-size: 19px;
      font-weight: 800;
    }

    h2 {
      font-size: 19px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

    h1 {
      font-size: 19px;
      font-weight: 800;
    }

    h2 {
      font-size: 19px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

    h1 {
      font-size: 24px;
      font-weight: 800;
    }

    h2 {
      font-size: 19px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

    h1 {
      font-size: 19px;
      font-weight: 800;
    }

    h2 {
      font-size: 19px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

    h1 {
      font-size: 19px;
      font-weight: 800;
    }

    h2 {
      font-size: 19px;
      font-weight: 800;
    }

    h3 {
      font-size: 15px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}


/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    h1 {
      font-size: 24px;
      font-weight: 800;
    }

    h2 {
      font-size: 22px;
      font-weight: 800;
    }

    h3 {
      font-size: 17px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {

    h1 {
      font-size: 24px;
      font-weight: 800;
    }

    h2 {
      font-size: 22px;
      font-weight: 800;
    }

    h3 {
      font-size: 17px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 1) {

    h1 {
      font-size: 24px;
      font-weight: 800;
    }

    h2 {
      font-size: 22px;
      font-weight: 800;
    }

    h3 {
      font-size: 17px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {

    h1 {
      font-size: 24px;
      font-weight: 800;
    }

    h2 {
      font-size: 22px;
      font-weight: 800;
    }

    h3 {
      font-size: 17px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {

    h1 {
      font-size: 24px;
      font-weight: 800;
    }

    h2 {
      font-size: 22px;
      font-weight: 800;
    }

    h3 {
      font-size: 17px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {

    h1 {
      font-size: 24px;
      font-weight: 800;
    }

    h2 {
      font-size: 22px;
      font-weight: 800;
    }

    h3 {
      font-size: 17px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 1112px)
  and (-webkit-min-device-pixel-ratio: 2) {

    h1 {
      font-size: 24px;
      font-weight: 800;
    }

    h2 {
      font-size: 22px;
      font-weight: 800;
    }

    h3 {
      font-size: 17px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 834px) 
  and (max-device-width: 834px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {

    h1 {
      font-size: 24px;
      font-weight: 800;
    }

    h2 {
      font-size: 22px;
      font-weight: 800;
    }

    h3 {
      font-size: 17px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1112px) 
  and (max-device-width: 1112px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {

    h1 {
      font-size: 24px;
      font-weight: 800;
    }


    h2 {
      font-size: 22px;
      font-weight: 800;
    }

    h3 {
      font-size: 17px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {

    h1 {
      font-size: 24px;
      font-weight: 800;
    }


    h2 {
      font-size: 22px;
      font-weight: 800;
    }

    h3 {
      font-size: 17px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {

    h1 {
      font-size: 24px;
      font-weight: 800;
    }


    h2 {
      font-size: 22px;
      font-weight: 800;
    }

    h3 {
      font-size: 17px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {

    h1 {
      font-size: 24px;
      font-weight: 800;
    }

    h2 {
      font-size: 22px;
      font-weight: 800;
    }

    h3 {
      font-size: 19px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 13px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}


/* ----------- Moto 360 Watch ----------- */
@media 
  (max-device-width: 218px)
  and (max-device-height: 281px) { 

    h1 {
      font-size: 20px;
      font-weight: 800;
    }

    h2 {
      font-size: 19px;
      font-weight: 800;
    }

    h3 {
      font-size: 17px;
    }

    h4 {

      font-size: 15px;

    }

    span {

      font-size: 15px !important;

    }

    li {

      font-size: 15px;

    }

    th {

      font-size: 15px;

    }

    td {

      font-size: 16px;

    }

}

/* ----------- Non-Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (-webkit-min-device-pixel-ratio: 1) { 

    h1 {
      font-size: 22px;
      font-weight: 800;
    }

    h2 {
      font-size: 23px;
    }

    h3 {
      font-size: 14px;
    }

    span {

      font-size: 13.5px !important;

    }

    li {

      font-size: 13.5px;

    }

    th {

      font-size: 13.5px;

    }

    td {

      font-size: 13.5px;

    }

    #Layer_1 {

      width: 25px;
    
    }

}

/* ----------- Retina Screens ----------- */
@media screen 
  and (min-device-width: 1200px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 

    h1 {
      font-size: 22px;
      font-weight: 800;
    }

    h2 {
      font-size: 12px;
      font-weight: 800;
    }

    h3 {
      font-size: 14px;
    }

    h4 {

      font-size: 11px;

    }

    span {

      font-size: 13.5px !important;

    }

    li {

      font-size: 13.5px;

    }

    th {

      font-size: 14px;

    }

    td {

      font-size: 13.5px;

    }

    #Layer_1 {

      width: 25px;
    
    }
    
    

}

#Layer_2 {
    
  width: 1.3rem;
  margin-right: 10px;

}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 30px;
}

.LoadingContainer {

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50% , -50%);
  z-index: 99999999999;
  /* background-color: white; */
  width: 300px;
  height: 180px;
  display: flex;
  justify-content: safe;
  align-items: center;
  flex-direction: column;
  transition: 1s;

}

.LoadingWrapper {

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50% , -50%);
  z-index: 99999999999;
  background-color: rgba(109, 109, 109, 0.178);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

}


.LoadingContainer > img {

  background-color: transparent;
  width: 10rem;

}

.LoadingContainer > span {

  margin-top: -24px;
  font-weight: 500;
  font-size: 16px !important;

}